@import "./styles/vars/config";
@import "./styles/vars/colors";
@import "./styles/base";

:root{
  --theme-color: #3fbb5d;
}


strong {
  font-weight: bold;
}

.button {
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 200px;
  height: 50px;
  line-height: 50px;
  border-radius: 30px;
  text-align: center;
  font-size: 12px;
  font-weight: 500;
  padding-left: 2em;
  padding-right: 2em;
  background-color: var(--theme-color);
  color: #fff;
  border: 0;
  text-transform: uppercase;
  outline: none;

  &:disabled {
    background-color: #dadada;
    color: #757575;
  }

  &.plain{
    background: transparent;
    color: #000;
    min-width: 1rem;
    padding-left: 0;
    padding-right: 0;
  }
}

.form {
  max-width: 600px;
  margin-left: auto;
  margin-right: auto;
  font-size: 14px;

  &--title {
    text-align: center;
  }

  &--note {
    font-size: 12px;
    margin-bottom: 20px;
  }

  &--error {
    color: red;
    font-size: 12px;
    margin-top: 12px;
    background-color: #fce4e4;
    border: 1px solid #fcc2c3;
    padding: 10px 20px;
  }

  &--success {
    color: #270;
    background-color: #DFF2BF;
    font-size: 12px;
    margin-top: 12px;
    border: 1px solid #270;
    padding: 10px 20px;
  }

  a {
    color: inherit;
    text-decoration: underline;
  }

  &-actions {
    margin-top: 30px;
    display: flex;
    justify-content: space-around;
  }
}

.form-items {
  display: flex;
  margin-left: -10px;
  margin-right: -10px;
  flex-wrap: wrap;
}

.form-item {
  padding-left: 10px;
  padding-right: 10px;
  width: 50%;
  margin-bottom: 24px;

  @include mediaOnly(sm) {
    width: 100%;
  }

  &--email {
    width: 100%;
  }

  label {
    display: block;
    font-size: 14px;
    margin-bottom: 10px;
  }

  input[type=text],
  input[type=email],
  input[type=password],
  select {
    display: block;
    width: 100%;
    border: 2px solid #000;
    height: 40px;
    padding: 0 1em;
    font-size: 16px;
    box-sizing: border-box;

    &.error {
      border-color: red;
    }
  }
  span.error{
    display: block;
    width: 100%;
    color: red;
    font-size: 12px;
    margin-top: 4px;
  }
}

.privacy-box {
  max-height: 220px;
  overflow: auto;
  border: 1px solid #eee;
  padding: 15px;
  margin: 40px 0;
}

.register {
  padding-top: 40px;
  padding-bottom: 40px;
}

.privacy-text {
  font-size: 14px;
  line-height: 1.5;
  font-weight: 300;
  margin-bottom: 20px;
}

.input-container {
  margin-bottom: 2em;
}

.checkbox {
  position: relative;
  min-height: 20px;
  display: flex;
  align-items: center;

  input {
    position: absolute;
    width: 20px;
    left: 0;
    height: 20px;
    top: 0;
    opacity: 0;

    &:checked + label:after {
      opacity: 1;
    }
  }

  > label {
    display: block;
    padding-left: 35px;
    margin-bottom: 0;
    line-height: 1.5;
    font-weight: 300;

    &:before {
      content: "";
      width: 20px;
      height: 20px;
      border: 1px solid #000;
      position: absolute;
      left: 0;
      top: 0;
    }

    &:after {
      content: "";
      width: 12px;
      height: 12px;
      background: #000;
      position: absolute;
      left: 4px;
      top: 4px;
      opacity: 0;
    }
  }
}

.radios {
  display: flex;
  align-items: center;

  .radio {
    position: relative;
    min-height: 30px;
    display: flex;
    align-items: center;
    margin-right: 20px;

    input {
      position: absolute;
      width: 100%;
      left: 0;
      height: 100%;
      top: 0;
      opacity: 0;

      &:checked + label:after {
        opacity: 1;
      }
    }

    label {
      display: block;
      padding-left: 45px;
      margin-bottom: 0;

      &:before {
        content: "";
        width: 30px;
        height: 30px;
        border: 1px solid #000;
        border-radius: 20px;
        position: absolute;
        left: 0;
        top: 0;
      }

      &:after {
        content: "";
        width: 14px;
        height: 14px;
        background: #000;
        border-radius: 20px;
        position: absolute;
        left: 8px;
        top: 8px;
        opacity: 0;
      }
    }
  }
}

.success_message {
  text-align: center;
  max-width: 780px;
  margin-left: auto;
  margin-right: auto;
  padding-top: 100px;
  padding-bottom: 100px;
}

.account {
  padding-top: 40px;
  padding-bottom: 40px;

  &--title{
    font-size: 30px;
    font-weight: 700;
    text-align: center;
    margin-bottom: 20px;
  }

  &--inner {
    max-width: 100%;
    width: 470px;
    margin: 0 auto;
    box-sizing: border-box;
    border: 1px solid #eee;
    padding-top: 20px;
  }

  &--title{

  }

  &--link {
    height: 60px;
    background-color: #fff;
    display: flex;
    align-items: center;
    padding: 0 20px;
    width: 100%;
    border: 0;
    border-top: 1px solid #eee;
    font-size: 18px;
    font-weight: 500;
    position: relative;
    color: #000;

    &:after {
      content: "";
      height: 7px;
      width: 7px;
      border-right: 2px solid #bbb;
      border-bottom: 2px solid #bbb;
      transform: rotate(-45deg);
      position: absolute;
      right: 20px;
    }
  }
  &--actions{
    background: #eee;
    padding: 20px;
    text-align: center;
    display: flex;
    justify-content: center;
  }
}

.password-form{
  max-width: 500px;


  .form-item{
    width: 100%;
  }
  .form--title{
    margin-bottom: 1em;
  }
}

.alert{
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  display: none;
  align-items: center;
  justify-content: center;
  background: rgba(0,0,0,.288);

  &.show{
    display: flex;
  }

  &--content{
    box-shadow: 0 11px 15px -7px rgba(0,0,0,.2),0 24px 38px 3px rgba(0,0,0,.14),0 9px 46px 8px rgba(0,0,0,.12);
    background: #fff;
    color: rgba(0,0,0,.87);
    margin-left: auto;
    margin-right: auto;
    max-width: 450px;
    border-radius: 4px;
  }

  &--text{
    padding: 29px 15px 21px;
    font-size: 16px;
    line-height: 24px;
    font-weight: 400;
    word-wrap: break-word;
    white-space: pre-wrap;
  }
  &--buttons{
    display: flex;
    border-top:1px solid #efefef;

    button{
      flex: 1;
      border: 0;
      border-radius: 0;
      color: #424242;
      font-size: 16px;
      padding: 0 20px;
      font-weight: 500;
      width: 100%;
      height: 45px;
      display: flex;
      justify-content: center;
      align-items: center;
      background: transparent;

      + button{
        border-left: 1px solid #efefef;
      }
    }
  }
}

.loader{
  position: fixed;
  z-index: 999;
  width: 100%;
  height: 100%;
  background-color: rgba(255,255,255,.5);
}



.class-hover {
  color: #ffffff;
  text-decoration: none;
  cursor: pointer;
  background-color: #006750;
}

