// Colors
$yellow: #ff9a02;
$green: #308d4c;
$white: #ffffff;
$black: #000000;

a {
  color: #000;
  text-decoration: none;
}

img {
  display: block;
  width: 100%;
  height: auto;
  object-fit: contain;
}


.nomob {
  @media (max-width: 991px) {
    display: none;
  }
}

.equilibra {
  padding: 40px 0;

  &--logo {
    margin-bottom: 70px;
    padding: 0 20px;
  }
  &--logo-image {
    max-width: 300px;
    margin: 0 auto;
  }
  &--logo-text {
    text-align: center;
    text-transform: uppercase;
    font-size: 18px;
    font-weight: bold;
    margin-top: 40px;
    max-width: 900px;
    margin-left: auto;
    margin-right: auto;
  }
  &--logo-title {
    text-align: center;
    text-transform: uppercase;
    font-size: 32px;
    font-weight: bold;
    margin-top: 60px;
  }
  &--card-wrapper {
    display: flex;
    justify-content: center;

    @media(max-width: 1023px) {
      flex-wrap: wrap;
    }
  }
  &--card {
    width: calc(100% / 4);
    margin-right: 20px;
    padding: 0 0 20px 0;
    display: flex;
    flex-direction: column;
    border-radius: 32px;
    border: 5px solid;
    overflow: hidden;

    @media(max-width: 1023px) {
      width: 40%;
      margin: 0 3%;
      margin-bottom: 24px;

    }

    @media(max-width: 767px) {
      width: 100%;
      margin-right: 0;
    }
    @media(min-width: 1024px) {
      &:last-child {
        margin-right: 0;
      }
    }

    &-image{
      border-radius: 0 0 30px 30px;
      overflow: hidden;
    }
  }

  &--card-text {
    padding: 30px 20px 40px 20px;
    flex-grow: 1;
    text-align: center;
    font-size: 18px;
    line-height: 1.2;
    font-weight: bold;
    color: $white;
  }
  &--card-btn {
    font-size: 16px;
    font-weight: bold;
    text-transform: uppercase;
    text-align: center;
    padding-left: 24px;
    padding-right: 24px;
    span {
      display: block;
      width: 100%;
      margin: 0 auto;
      padding: 10px 0;
      background-color: $white;
      border-radius: 30px;
      color: $green;
    }
  }
  .store,
  .market,
  .store-equilibra {
    background-color: $green;
    border-color: $green;
  }
  .amazon {
    background-color: $yellow;
    border-color: $yellow;
    .equilibra--card-btn span {
      color: $yellow;
    }
  }
}