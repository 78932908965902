


* {
  box-sizing: border-box;
}

body{
  padding: 0;
  margin: 0;
  font-family: 'Montserrat', sans-serif;
}

img{
  max-width: 100%;
  height: auto;
}


.container-content,
.container{
  max-width: 1366px;
  padding-left: 20px;
  padding-right: 20px;
  width: 100%;
  margin: 0 auto;
  box-sizing: border-box;
}

footer{
  background-color: #f5f5f5;
  font-family: 'Montserrat', sans-serif;
  .configuration{
    padding: 0;
  }


  .container-content {
    @include mediaOnly(sm) {
      width: 100%;
      padding: 0;
    }
    &.phone {
      max-width: unset;
      background: white;
      text-align: center;
      padding-top: 11px;
      padding-bottom: 11px;
      height: 61px;

      img {
        height: 39px;
      }
    }
    &.green {
      max-width: unset;
      background-color: #3fbb5d;
      text-align: center;
      color: #ffffff;
      font-size: 11px;
      padding: 36px 15px;
      line-height: 1.5;
    }
  }
  .menus{
    width: 80%;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    padding: 20px 0;
    @include mediaOnly(sm) {
      padding: 34px 0;
    }

    a {
      color: #404040;
      text-decoration: none;
      font-size: 12px;
      line-height: 2.2;
      @include mediaOnly(sm) {
        font-weight: 500;
      }
    }
    @include mediaUp(md) {
      a {
        margin-right: 55px;
        &:last-of-type {
          margin-right: 0;
        }
      }
    }
    @include mediaOnly(md){
      flex-wrap: wrap;
      width: 100%;

      a {
        text-align: center;
      }
    }

    @include mediaOnly(sm){
      flex-direction: column;
    }
  }


  .secondary-nav {
    padding: 68px 0 38px;
    @include mediaUp(md) {
      margin-bottom: 48px;
    }
    @include mediaOnly(sm) {
      background-color: white;
      padding: 20px;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: row;
    }
  }
  .logo{
    @include mediaUp(md) {
      position: absolute;
      transform: translateX(-50%);
      left: 50%;
    }

    @include mediaOnly(sm) {
      max-width: 64px;
    }

    img{
      width: 86px;
      height : auto;
      display: block;
      max-width: 100%;
      @include mediaUp(md) {
        margin-top: -10px;
      }
    }

  }
  .copy {
    display: flex;
    flex-wrap: nowrap;
    justify-content: flex-end;
    img {
      width: 26px;
      height: 29px;
      margin-right: 8px;
      @media (max-width:320px) {
        max-width: 13px;
      }
    }
    &-text {
      font-size: 10px;
      @media (max-width:320px) {
        font-size: 6px;
      }
    }
  }
}

.header{
  background: white;
  min-height: 70px;
  z-index: 4;
  border-bottom: 1px solid #f0f0f0;

  &__logo-image-wrapper{
    width: 180px;
    height: 100%;
    display: flex;
    align-items: center;
    max-width: 86px;
    max-height: 43px;
  }

  &__top{
    display: flex;
    width: 100%;
    justify-content: space-between;
    box-sizing: border-box;
    flex-grow: 0;
    flex-shrink: 1;

    &-left{
      display: flex;
      height: 70px;
      align-items: center;
    }

    .close,.burger{
      display: none;
    }
    @media (max-width: 767px) {
      .burger{
        display: block;
        background: url('img/icon-burger.svg') no-repeat center;
        width: 40px;
        background-size: 80%;
      }
    }

    &-navbar{
      display: flex;
      align-items: center;

      nav{
        display: flex;
        min-height: 70px;
      }
      ul{
        list-style-type: none;
        margin: 0;
        padding: 0;
        display: flex;
        flex-wrap: wrap;
        align-items: center;

        li{
          box-sizing: border-box;
          display: flex;
          align-items: center;
          border: 0;
          outline: 0;
          height: 54px;
        }

        a{
          cursor: pointer;
          background-color: transparent;
          border: 0;
          border-radius: 0;
          font-size: 11px;
          height: 42px;
          line-height: 42px;
          padding: 0 10px;
          text-decoration: unset;
          text-align: left;
          color: #464646;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          display: flex;
          text-transform: uppercase;
          font-weight: bold;
          align-items: center;
        }
      }



      @media (max-width: 767px) {
        position: fixed;
        background-color: #fff;
        height: 100%;
        box-sizing: border-box;
        overflow: auto;
        width: 250px;
        z-index: 99;
        left: 0;
        box-shadow: 0 2px 4px rgba(0,0,0,.5);
        align-items: flex-start;
        transform: translateX(-120%);

        &.has-menu{
          transform: translateX(0);
        }


        nav{
          width: 100%;
          padding-top: 40px;
          position: relative;

          .close{
            position: absolute;
            right: 0;
            top: 0;
            width: 40px;
            height: 40px;
            border: 0;
            padding: 0;
            display: block;
            background: url('img/icon-close.svg') no-repeat center;
            background-size: 40%;
          }
        }

        ul{
          flex-direction: column;
          width: 100%;

          li{
            width: 100%;
            border-bottom: 1px solid #fff;
            height: auto;

            a{
              width: 100%;
              display: flex;
              align-items: center;
              position: relative;
              box-sizing: border-box;
              background-color: #3fb95d;
              color: #fff;
              padding: 0 33px;
              min-height: 72px;
              font-size: 16px;
              line-height: 24px;
              white-space: normal;
            }
          }
        }
      }
    }
  }
}