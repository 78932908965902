//## Override bootstap vars
@function map-get-next($map, $key, $fallback: false, $return: value) {

  // Check if map is valid
  @if type-of($map) == map {

    // Check if key exists in map
    @if map-has-key($map, $key) {

      // Init index counter variable
      $i: 0;

      // Init key index
      $key-index: false;

      // Traverse map for key
      @each $map-key, $map-value in $map {
        // Update index
        $i: $i + 1;

        // If map key found, set key index
        @if $map-key == $key {
          $key-index: $i;
        }

        // If next index return next value or key based on $return
        @if $i == $key-index + 1 {
          @if $return == key {
            @return $map-key;
          } @else {
            @return $map-value;
          }
        }

        // If last entry return false
        @if $i == length($map) {
          @return $fallback;
        }
      }

      @warn 'No next map item for key #{$key}';
      @return $fallback;
    }

    @warn 'No valid key #{$key} in map';
    @return $fallback;
  }

  @warn 'No valid map';
  @return $fallback;
}

$font-family-sans-serif : 'Open Sans', sans-serif;

$grid-gutter-width      : 24px;

$container-max-widths   : '';

$icomoon-font-path      : '../fonts/nutella-icon/fonts';

$grid-breakpoints       : (
        xs: 0,
        sm: 576px,
        md: 768px,
        lg: 992px,
        xl: 1280px
);

$app-breakpoints       : (
        xs: 0,
        sm: map-get($grid-breakpoints, sm),
        md: map-get($grid-breakpoints, md),
        lg: map-get($grid-breakpoints, lg),
        xl: map-get($grid-breakpoints, xl),
        xxl: 1440px
);

@mixin mediaUp($breakpoint) {
  @if map-has-key($app-breakpoints, $breakpoint) {

    @media (min-width : #{map-get($app-breakpoints, $breakpoint)}) {
      @content;
    }

  } @else {
    @warn "Unfortunately, no value could be retrieved from `#{$breakpoint}`. "
        + "Please make sure it is defined in `$breakpoints` map.";
  }
}
@mixin mediaOnly($breakpoint) {
  @if map-has-key($app-breakpoints, $breakpoint) {

    @media (max-width : #{map-get-next($app-breakpoints, $breakpoint) - 1px}) {
      @content;
    }

  } @else {
    @warn "Unfortunately, no value could be retrieved from `#{$breakpoint}`. "
        + "Please make sure it is defined in `$breakpoints` map.";
  }
}

@mixin rtl() {
  [dir="rtl"] & {
    @content;
  }
}
@mixin ltr() {
  [dir="ltr"] & {
    @content;
  }
}

@function get-vw($font,$vw-viewport:1218) {
  $vw-context : $vw-viewport * 0.01 * 1px;
  @return $font / $vw-context * 1vw;
}

@function get-font-vh($font,$vw-viewport:812) {
  $vw-context : $vw-viewport * 0.01 * 1px;
  @return $font / $vw-context * 1vh;
}

@mixin iconFont(){
  font-family: '#{$icomoon-font-family}';
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
@mixin fontCustom(){
  font-family: 'Abril Fatface', cursive;
}



@function em($target,$base:16px){
  $target : $target / 1px;
  $base : $base / 1px;
  @return ($target / $base) * 1em;
}
@mixin blockTitle($color:$red){
  font-size   : em(40px);
  font-weight : 700;
  line-height : 0.97;
  color       : $color;

  @include mediaOnly(sm) {
    font-size : 32px;
  }
}


@mixin defaultStartAnimation($animation:slideInUpView){
  animation: $animation 1.5s cubic-bezier(0.215, 0.61, 0.355, 1) paused both;
}
@mixin defaultEndAnimation(){
  animation-play-state: running;
}

@mixin hover-supported {
    .has-hover &{
      @content;
    }
}

@mixin cardShadow(){
  box-shadow: 0 10px 20px 0 rgba(0,0,0,.1);
  will-change: transform;
  transform-origin: center;
  transition: all .1s ease-out;
}
@mixin cardShadowHover(){
  transform: translate3d(0,2px,0);
  box-shadow: 0 3px 10px 0 rgba(0,0,0,.08);
}
